import {useIntl} from "react-intl";
import {PageTitle} from "../../../_metronic/layout/core";
import {Helmet} from "react-helmet-async";
import {Row, Col, Card} from "react-bootstrap";
import PayoutService, {PayoutRoot} from "../dashboard/payouts/payout.service";
import {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import TableUtilities from "components/dist/tables/table-utilities";
import Skeleton from "components/dist/skeleton/Skeleton";
import {PayoutSkeletonItem} from "./PayoutItemSkeleton";


const ViewAwaitingPayout = () => {
    const [payoutsRoot, setPayoutsRoot] = useState<PayoutRoot | null>(null);
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        (async () => {
            setLoading(true);

            const response = await PayoutService.fetchCurrentPayout();

            setPayoutsRoot(response);
            setLoading(false);
        })()
    }, [])

    return (
        <Row className="g-6">
            <Col md={8}>
                <Card className="pt-4" title="">
                    <Card.Body>
                        <div>
                            <h2 className='d-inline-block align-middle'>
                                Pending Payout
                            </h2>
                        </div>

                        <div className="mt-4">
                            {loading
                                ? <PayoutSkeletonItem />
                                : payoutsRoot?.payouts?.map(payout => (
                                    <div className="d-flex mt-7">
                                        <div className="me-5 bg-white p-2 rounded">
                                            <Link to={`/inventory/items/${payout.orderItem.item.id}`}>
                                                <div
                                                    style={{
                                                        backgroundImage: `url("${payout.orderItem.item.product.imageUrl}")`,
                                                        backgroundSize: 'contain',
                                                        backgroundRepeat: 'no-repeat',
                                                        backgroundPosition: 'center center',
                                                        height: '90px',
                                                        width: '90px'
                                                    }}>
                                                </div>
                                            </Link>
                                        </div>
                                        <div className="d-flex justify-content-center flex-column gap-1 w-100">
                                        <span className="h1 text-dark fw-bolder mb-1 fs-5">
                                            <Link className="text-white"
                                                  to={`/inventory/items/${payout.orderItem.item.id}`}>
                                                {payout.orderItem.item.product.title}
                                            </Link>

                                            <span className="float-end">
                                                {TableUtilities.formatCurrencyCents(payout.orderItem.payoutAmount)}
                                            </span>
                                        </span>
                                            <span className="text-muted text-capitalize">
                                            {payout.orderItem.item.size} - {payout.orderItem.item.shape}

                                                <span className="text-muted text-capitalize float-end">
                                                Sold Price: {TableUtilities.formatCurrencyCents(payout.orderItem.subtotal)}
                                            </span>
                                        </span>
                                            <div className="text-muted">{payout.orderItem.item.product.sku}</div>
                                        </div>
                                    </div>
                                ))}
                        </div>
                    </Card.Body>
                </Card>
            </Col>
            <Col md={4}>
                <Card className="pt-4" title="">
                    <Card.Body>
                        <div className="pb-7">
                            <h2>Payout Details</h2>
                        </div>

                        <div className="d-flex flex-column gap-1">
                            <div className="d-flex justify-content-between w-100 fs-6">
                                <p className="text-muted">Item Count</p>

                                <p className="text-white">
                                    {loading ? <Skeleton style={{width: '2rem'}}/> : payoutsRoot?.details?.payoutCount}
                                </p>
                            </div>
                            <div className="d-flex justify-content-between w-100 fs-6">
                                <p className="text-muted">Subtotal</p>
                                <p className="text-white">
                                    {loading
                                        ? <Skeleton style={{width: '5rem'}}/>
                                        : TableUtilities.formatCurrencyCents(payoutsRoot?.details?.subtotal ?? 0.00)
                                    }
                                </p>
                            </div>
                            <div className="d-flex justify-content-between w-100 fs-6">
                                <p className="text-muted">Payout Amount</p>
                                <p className="text-white">
                                    {loading
                                        ? <Skeleton style={{width: '4.5rem'}}/>
                                        : TableUtilities.formatCurrencyCents(payoutsRoot?.details?.payoutAmountSubtotal ?? 0.00)
                                    }
                                </p>
                            </div>
                        </div>
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    );
}

const ViewAwaitingPayoutPage = () => {
    const intl = useIntl();
    return (
        <>
            <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: "MENU.PENDING_PAYOUT"})}</PageTitle>
            <Helmet>
                <title>{intl.formatMessage({id: intl.formatMessage({id: "MENU.PENDING_PAYOUT"})})}</title>
            </Helmet>
            <ViewAwaitingPayout/>
        </>
    )
}


export default ViewAwaitingPayoutPage;