import SuperTokens from "supertokens-auth-react";
import Multitenancy, {AllowedDomainsClaim} from "supertokens-auth-react/recipe/multitenancy";
import EmailVerification from "supertokens-auth-react/recipe/emailverification";
import EmailPassword from "supertokens-auth-react/recipe/emailpassword";
import Session from "supertokens-auth-react/recipe/session";
import axios from "axios";
import TenantService from "../tenant/services/TenantService";

const getCssVarRgb = (varName: string) => {
    const hex = getComputedStyle(document.documentElement).getPropertyValue(varName).trim();

    let bigint = parseInt(hex.substring(1), 16);
    let r = (bigint >> 16) & 255;
    let g = (bigint >> 8) & 255;
    let b = bigint & 255;

    return `${r} ${g} ${b}`;
}


const style = `
    [data-supertokens] {
        font-family: Inter, Helvetica, "sans-serif" !important;
    }

    [data-supertokens~=container] {
        --palette-background: ${getCssVarRgb('--bs-body-bg')};
        --palette-inputBackground: ${getCssVarRgb('--bs-gray-100')};
        --palette-inputBorder: ${getCssVarRgb('--bs-gray-100')};
        --palette-primary: ${getCssVarRgb('--bs-primary')};
        --palette-primaryBorder: ${getCssVarRgb('--bs-primary')};
        --palette-textLink: ${getCssVarRgb('--bs-primary')};
        --palette-textTitle: 255, 255, 255;
        --palette-textLabel: 255, 255, 255;
        --palette-textPrimary: 255, 255, 255;
        --palette-error: 173, 46, 46;
        --palette-textInput: 169, 169, 169;
        
        font-family: Inter, Helvetica, 'sans-serif';
    }
    
    [data-supertokens~="superTokensBranding"] {
        display: none !important;
    }
    
    [data-supertokens~="divider"] {
        border-bottom-color: var(--bs-dark-light);
    }
    
    [data-supertokens~="input"] {
        font-family: Inter, Helvetica, 'sans-serif';
        font-size: var(--input-font-size);
    }
    
    [data-supertokens~="row"] {
        width: 85%;
    }
`;

export type Tenant = {
    id: string;
    title: string;
    organizationSetting: Organization,
    subdomain: string
};

type Organization = {
    logoUrl: string;
    faviconUrl: string;
};

export const initSupertokens = () => {
    SuperTokens.init({
        appInfo: {
            // learn more about this on https://supertokens.com/docs/emailpassword/appinfo
            appName: "Sneakers",
            apiDomain: process.env.REACT_APP_API_DOMAIN!,
            websiteDomain: window.location.origin,
            apiBasePath: "/auth",
            websiteBasePath: "/auth",
        },
        usesDynamicLoginMethods: true,
        recipeList: [
            Multitenancy.init({
                override: {
                    functions: (oI) => {
                        return {
                            ...oI,
                            getTenantId: async () => {    // Extract the hostname from the URL
                                const cachedTenantData = TenantService.getTenantFromLocalStorage();

                                if(cachedTenantData) {
                                    return cachedTenantData.id;
                                }

                                const tenantData = await TenantService.fetchTenantData();

                                return tenantData.id;
                            }
                        }
                    }
                },
            }),
            EmailVerification.init({
                style: style,
                mode: "REQUIRED", // or "OPTIONAL"
            }),
            EmailPassword.init({
                style: style,
                signInAndUpFeature: {
                    signUpForm: {
                        formFields: [
                            {
                                id: "name",
                                label: "Full name",
                                placeholder: "First name and last name",

                                /* Validation method for name */
                                validate: async (value: string) => {
                                    const nameRegex = /^[a-zA-Z]+\s[a-zA-Z]+$/;
                                    const result = nameRegex.test(value);
                                    if (result) {
                                        return undefined;
                                    }
                                    return "Please enter your full name";
                                }
                            },
                            {
                                id: "phone",
                                label: "Phone number",
                                placeholder: "123-456-7890",

                                /* Validation method for phone number */
                                validate: async (value) => {
                                    const phoneRegex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
                                    const result = phoneRegex.test(value);
                                    if (result) {
                                        return undefined;
                                    }
                                    return "Invalid phone number";
                                }
                            }
                        ]
                    }
                },
                getRedirectionURL: async (context) => {
                    if (context.action === "SUCCESS") {
                        if (context.redirectToPath !== undefined) {
                            // we are navigating back to where the user was before they authenticated
                            return context.redirectToPath;
                        }
                        return "/dashboard";
                    }
                    return undefined;
                }
            }),
            Session.init({
                override: !process.env.NODE_ENV || process.env.NODE_ENV === 'development' ? undefined : {
                    functions: (oI) => ({
                        ...oI,
                        getGlobalClaimValidators: ({ claimValidatorsAddedByOtherRecipes }) => [
                            ...claimValidatorsAddedByOtherRecipes,
                            {
                                ...AllowedDomainsClaim.validators.hasAccessToCurrentDomain(),
                                onFailureRedirection: async () => {
                                    let claimValue = await Session.getClaimValue({
                                        claim: AllowedDomainsClaim,
                                    });

                                    return "https://" + claimValue![0];
                                },
                            },
                        ],
                    }),
                },
            })
        ]
    });
}