
/* eslint-disable jsx-a11y/anchor-is-valid */
import {useUser} from "../../../../app/modules/auth/AuthWrapper";
import Skeleton from "components/dist/skeleton/Skeleton";
import React from "react";

const AsideToolbar = () => {
  const {user} = useUser()

  return (
    <>
      {/*begin::User*/}
      <div className='aside-user d-flex align-items-sm-center justify-content-center py-5'>
        {/*begin::Wrapper*/}
        <div className='aside-user-info flex-row-fluid flex-wrap'>
          {/*begin::Section*/}
          <div className='d-flex'>
            {/*begin::Info*/}
            <div className='flex-grow-1 me-2'>
              {/*begin::Username*/}
              {user ? (
                  <a href='#' className='text-white text-hover-primary fs-6 fw-bold'>
                    {user?.fullName}
                  </a>
              ) : (
                  <Skeleton className='col-8' />
              )}
              {/*end::Username*/}

              {/*begin::Description*/}
              {user ? (
                  <span className='text-gray-600 fw-bold d-block text-capitalize fs-8 mb-1'>
                    {user?.userType} - {user?.tier?.name}
                  </span>
              ) : (
                  <Skeleton className='col-5' />
              )}
              {/*end::Description*/}
            </div>
            {/*end::Info*/}
          </div>
          {/*end::Section*/}
        </div>
        {/*end::Wrapper*/}
      </div>
      {/*end::User*/}

      {/*start:Separator*/}
      <div className='separator mx-1 my-4 mt-0'></div>
      {/*end:Separator*/}
    </>
  )
}

export {AsideToolbar}
