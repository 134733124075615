import Skeleton from "components/dist/skeleton/Skeleton";

export const PayoutSkeletonItem = () => {
    return <>
        <div className="d-flex mt-7">
            <Skeleton className='me-5 p-2' style={{
                minHeight: '90px',
                minWidth: '90px',
                boxSizing: 'content-box',
            }}/>
            <div className="d-flex justify-content-center flex-column gap-1 w-100">
                <span className="h1 text-dark fw-bolder mb-1 fs-5">
                    <Skeleton className="col-8"/>
                    <Skeleton className="col-2 float-end"/>
                </span>
                <span className="text-muted text-capitalize">
                    <Skeleton className="col-2"/>
                    <Skeleton className="col-3 float-end"/>
                </span>
                <div className="text-muted">
                    <Skeleton className="col-1"/>
                </div>
            </div>
        </div>
    </>
}
